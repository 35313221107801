// WRITE ARTICLE
export const proofOfKnowledge = {
  order: 4,
  name: "Unlock what you already know",
  keywords: "prove proof validate reward",
  subcategory: "Validate database changes",
  markdown: `# Unlock what you already know

  ## How it works

  ## Rewards`,
};
